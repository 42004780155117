/* Google font family Inter is imported */
@import url(https://fonts.googleapis.com/css?family=Inter);

.background-container {
    background: #5d5d5d;
}


.normal-btn {
    background-color: var(--normal-btn);
}

.normal-btn-hover {
    background-color: #8d8d8d;
}

.text-primary-color {
    color: #ec8129;
}

.normal-btn-inactive {
    background-color: var(--normal-btn-inactive);
}

.text-secondary-color {
    color: #ec8129;
}

.background-active-contact {
    background: var(--background-active-contact);
}

.background-config-box {
    background: #fff8f0;
}

.background-config-box-border {
    border: var(--background-config-box-border);
}

.normal-btn-border {
    border: var(--normal-btn-border);
}

.config-box-border {
    border: var(--config-box-border);
}

.background-chat {
    background: #fff8f0;
}

.disabled-btn-text {
    background: var(--disabled-btn-text);
}

.error-text {
    color: var(--error-text);
}

.error-background {
    background: var(--error-background);
}

.attachment-background {
    background: var(--attachment-background);
}

.dm3-root {
    height: 100%;
    position: relative;
    width: 100%;
    border-radius: inherit;
    font-family: 'Inter'
}
