.emoji-modal-container {
    position: absolute;
    z-index: 1;
    bottom: 3rem;
}

/* Customization of Emoji mart CSS */
em-emoji-picker {
    --rgb-accent: var(--emoji-modal-accent-color);
    --rgb-background: var(--emoji-modal-background-color);
    --rgb-color: var(--emoji-modal-text-color);
}

/* =================== Mobile Responsive CSS =================== */

@media only screen and (max-width: 800px) {
    .emoji-modal-container {
        bottom: 3.5rem;
    }
}