.modal-container {
    z-index: 1;
    left: 0;
    top: 0;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: brightness(0.3);
}

.close-modal-icon {
    width: 1.8rem;
    height: auto;
    cursor: pointer;
}

.modal-btn-disabled {
    background: #5d5d5d;
    color: #fff8f0;
    border: none;
}

.modal-btn-active {
    background-color: #ec8129;
    border: #ec8129;
    color: #fff8f0;
}

.modal-btn-active:hover {
    background-color: #fff8f0;
    color: #ec8129;
    border: #ec8129;
}

.show-error {
    display: flex;
}

.hide-error {
    display: none;
}

.err-background {
    background: var(--error-background) !important;
}

.invisible {
    visibility: hidden;
}
