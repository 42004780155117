.chat-screen-container {
    display: flex;
    flex-direction: column;
    border-radius: 0px !important;
    background-color: #fff8f0; /* Changed to the new background color */
    color: #5d5d5d; /* Changed to the new text color */
}

.right-view-outer-container {
    margin-left: 0rem;
}

.default-screen {
    height: 88vh;
    color: #5d5d5d; /* Changed to the new text color */
}
