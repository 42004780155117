/* Center the loader and make it full screen */
.loading {
    position: absolute;
    z-index: 999;
    height: 100%;
    width: 100%;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: none !important;
    background-color: rgba(0, 0, 0, 0.6); /* Dark semi-transparent background */
}

/* Loader animation container */
.loader-animation {
    display: flex;
    justify-content: space-between;
    width: 80px;
}

/* Block animation */
.block {
    width: 15px;
    height: 15px;
    background-color: #ec8129;
    border-radius: 4px;
    animation: blockBounce 1.2s infinite ease-in-out;
}

.block:nth-child(1) {
    animation-delay: 0s;
}
.block:nth-child(2) {
    animation-delay: 0.2s;
}
.block:nth-child(3) {
    animation-delay: 0.4s;
}
.block:nth-child(4) {
    animation-delay: 0.6s;
}

/* Bouncing blocks keyframes */
@keyframes blockBounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-15px);
    }
}

/* Loader content positioning and style */
.loader-content {
    color: #fff8f0;
    position: absolute;
    margin-top: 7rem;
    text-align: center;
    font-size: 1.2rem;
}

/* Mobile responsiveness */
@media only screen and (max-width: 800px) {
    .block {
        width: 10px;
        height: 10px;
    }
    .loader-animation {
        width: 60px;
    }
    .loader-content {
        font-size: 14px !important;
        margin-top: 4rem !important;
    }
}
